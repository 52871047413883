import React from 'react'
import {Link} from 'gatsby'
import Layout from "../components/layout"
import '../styles/fsdcs.css'

const fsdcs = () => {
    return(
        <Layout title="Full Stack developer course syllabus (2020 updated) &#8211; Crampete" image="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/blog/FS_Syllabus.png" description="Full Stack Developer course syllabus updated for 2020 has varied modules on HTML, CSS,Javascript, Python, ReactJS, NodeJS, etc. is explained clearly in the blog." keywords="Full Stack developer course syllabus (2020 updated)">
            <section className="row">
                <div className="col-md-2"></div>
                <div className="col-md-8">
                    <div className="fsdcs-f-image mb-5">
                        <img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/blog/FS_Syllabus.png" alt="Full Stack Developer Course Syllabus | Crampete" />
                    </div>
                    <div className="fsdcs-title">
                        <h1>Full Stack Developer Course Syllabus (2020 Updated)</h1>
                    </div>
                    <div className="fsdcs-outer">
                        <div className="table-of-contents">
                            <h2>Crampete's <b>Full Stack Developer course complete syllabus</b> has following modules:</h2>
                            <ul>
                                <li>Overview</li>
                                <li>HTML</li>
                                <li>CSS</li>
                                <li>Programming with Javascript</li>
                                <li>ReactJS Development</li>
                                <li>NodeJS Development</li>
                                <li>MongoDB</li>
                                <li>Python</li>
                                <li>Version Control System</li>
                                <li>6 Mini Projects</li>
                                <li>1 Capstone Project</li>
                            </ul>
                        </div>
                        <p>Crampete is an AI-powered online platform that was built to educate and equip students and any interested person with skills that will help them get a job and do well with it. We equally have a strong offline presence with our Crampete Learning centres operating in various cities across India, Singapore, Malaysia, Oman etc..</p>
                        <p>Also Read: <a href="https://timesofindia.indiatimes.com/home/education/news/crampete-sets-up-learning-centres-to-boost-the-rate-of-completion-of-online-tech-courses/articleshow/74518901.cms" target="_blank">Check what Times of India thinks about our course.</a></p>
                        <p>Our comprehensive HR research made us aware that the expectations that recruiters and freshers or a professional with a lateral career shift were way farther from reality. To lessen this gap and provide practical tools to equip students and professionals for the demanding job industry became the aim of this institute. We work tirelessly to that end and that is one reason why Crampete offers the “most comprehensive industry aligned job ready “Full stack developer course syllabus”.</p>
                        <div className="text-center mt-4 mb-4">
                            <img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/blog/FS_Syllabus_Image2.png" alt="Full Stack developer course syllabus (2020 updated) | Crampete" />
                        </div>
                        <h2>Full Stack Developer Course modules</h2>
                        <p>Full stack developer course modules are designed by the experts who have expert hands-on knowledge of the industry in addition to being excellent teachers. Campete promotes multifaceted learning experience. The students are involved in this hands-on course where they test out codes in labs sessions, work on mini projects, and also one capstone project all of which counts towards their credits for completing the course.</p>
                        <p>Full Stack developer course duration is 60 hours. All the batches are worked out within a similar time frame. This includes the online full stack developer courses as well. The syllabus has been designed with the time frame, the students, and the work experience needed in mind. The full stack developer course details are as follows:</p>
                        <p>Note: You can compare our syllabus with others <a href="https://www.simplilearn.com/full-stack-web-developer-mean-stack-certification-training" target="_blank">here.</a></p>
                        <div id="fsdcs-module-1">
                            <h2>Module 1 of Full stack developer course syllabus: HTML</h2>
                            <p><a href="https://www.w3schools.com/html/" target="_blank">HTML</a> is the basic and must have skill for every web developer. It is used and extended by various other technologies. To be completely able to understand how things work in web development, you should develop an understanding of HTML. In this section, you will learn</p>
                            <ul>
                                <li>Introduction to HTML</li>
                                <li>Browsers and HTML</li>
                                <li>Editor’s Offline and Online</li>
                                <li>Tags, Attribute and Elements</li>
                                <li>Doctype element</li>
                                <li>Comments</li>
                                <li>Headings, Paragraphs,Formatting text</li>
                                <li>Lists and Links</li>
                                <li>Images , Table</li>
                            </ul>
                        </div>

                        <div id="fsdcs-module-2">
                            <h2>Module 2 of Full stack developer course syllabus: CSS</h2>
                            <p><a href="https://www.w3.org/Style/CSS/Overview.en.html" target="_blank">CSS</a> is the other important language in the web tech must-learn trifecta. It will help you stylle, layout and control the behaviour and look and feel of the web apps that you build. In this module, you will learn:</p>
                            <ul>
                                <li>Introduction CSS</li>
                                <li>Applying CSS to HTML</li>
                                <li>Selectors, properties and values</li>
                                <li>CSS Colors, Backgrounds</li>
                                <li>CSS Box Model</li>
                                <li>CSS Margins,Padding,Borders</li>
                                <li>CSS Text and Font Properties</li>
                                <li>CSS General Topics</li>
                            </ul>
                        </div>

                        <div id="fsdcs-module-3">
                            <h2>Module 3 of Full stack developer course syllabus: Javascript</h2>
                            <p>The third of the must learn trifecta, Js is present in about 90% of the internet. To make sense of what you’re doing and to design and build new web apps, this language is indispensable. In this section you will learn the following topics:</p>
                            <p><b>Topics covered in this module:</b></p>
                            <ul>
                                <li>Introduction to JavaScript</li>
                                <li>Applying JavaScript (internal, external)</li>
                                <li>Understanding JS Syntax</li>
                                <li>Introduction to Document and Window Object</li>
                                <li>Variables,Operators</li>
                                <li>Data Types,Num Type Conversion</li>
                                <li>Math,String Manipulation</li>
                                <li>Objects,Arrays</li>
                                <li>Date and Time</li>
                                <li>Conditional Statements</li>
                                <li>Switch Case</li>
                                <li>Looping in JS</li>
                                <li>Functions</li>
                            </ul>
                        </div>

                        <div id="fsdcs-module-4">
                            <h2>Module 4 of Full stack developer course syllabus: ReactJS</h2>
                            <p><a href="https://reactjs.org/" target="_blank">ReactJS</a> is the best and most popular framework for frontend development. An integral part of the <Link to="courses/mern-stack-course-in-chennai/">MERN Stack</Link>, its community is great, and the demand for reactjs specialists is only increasing day by day. React is great for Rapid app development, SPAs and for creating awesome responsive and interactive web apps. In this topic you will learn:</p>
                            <ul>
                                <li>Introduction</li>
                                <li>Templating using JSX</li>
                                <li>Components, State and Props</li>
                                <li>Lifecycle of Components</li>
                                <li>Rendering List, Portals</li>
                                <li>Error Handling</li>
                                <li>Routers</li>
                                <li>Redux, Redux Saga</li>
                                <li>Immutable.js</li>
                                <li>Service side rendering</li>
                                <li>Unit testing</li>
                                <li>Webpack</li>
                            </ul>
                        </div>

                        <div id="fsdcs-module-5">
                            <h2>Module 5 of Full stack developer course syllabus: Node.js</h2>
                            <p>The <a href="https://nodejs.org/en/" target="_blank">Node.js</a> is a great skill to have. It is JS based and it completes the javascript full stack experience. It is a backend skill that’s very much in demand and pays well. In this module, you will learn the following:</p>
                            <ul>
                                <li>Node.js overview</li>
                                <li>Node.js – basics and setup</li>
                                <li>Node.js console</li>
                                <li>Node.js command utilities</li>
                                <li>Node.js modules</li>
                                <li>Node.js concepts</li>
                                <li>Node.js events</li>
                                <li>Node.js with Express.js</li>
                                <li>Node.js database access</li>
                            </ul>
                            <div className="text-center mt-4 mb-4">
                                <img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/blog/FS_Syllabus_Image1-1.png" alt="Full Stack developer course syllabus | Crampete" />
                            </div>
                        </div>

                        <div id="fsdcs-module-6">
                            <h2>Module 6 of Full stack developer course syllabus: MongoDB</h2>
                            <p>This is a data driven schemaless NoSql database. It is a great tool to know. The syntax is very similar to javascript making this that much easier to learn. You can use this for projects of any size and also very easy to scale up or down depending on your requirement. In this module, you will learn:</p>
                            <ul>
                                <li>SQL and NoSql concepts</li>
                                <li>Create and manage <a href="https://www.mongodb.com/" target="_blank">MongoDB</a></li>
                                <li>Migration of data into MongoDB</li>
                                <li>MongoDB with PHP</li>
                                <li>MongoDB with NodeJS</li>
                                <li>Services offered by MongoDB</li>
                            </ul>
                        </div>

                        <div id="fsdcs-module-7">
                            <h2>Module 7 of Full stack developer course syllabus: Python</h2>
                            <p>Learn the basics of <a href="https://www.python.org/#" target="_blank">python</a> and learn to use it to develop applications. Also learn to work with mongodb in python. This additional language is a value-added skill as python is increasingly in demand for full stack projects. In this module, you will learn:</p>
                            <ul>
                                <li>Python installation &amp; configuration</li>
                                <li>Developing a Python application</li>
                                <li>Connect MongoDB with Python</li>    
                            </ul>
                        </div>

                        <div id="fsdcs-module-8">
                            <h2>Module 8 of Full stack developer course syllabus: VCS</h2>
                            <p>Learn version control with Git. This is a bankable skill for every web developer so that they can collaborate and work as a team while working individually. It is also imperative to use VCS so that you can simplify work on identifying errors and also store every piece of coding that you’ve worked on.</p>
                        </div>

                        <div id="fsdcs-module-9">
                            <h2>Capstone Project</h2>
                            <p>The capstone project is the endgame to your full stack developer course. This is a medium for you to apply and exhibit what you have learnt in this course. You get to take your theoretical learning and apply it in a real-world setting.  This is a very important aspect of the full stack developer course syllabus.</p>
                            <p>The prerequisite for the capstone project is that you have completed the learning modules course on full stack developer, taken up on practising code at the lab sessions and also completed all your mini-projects, which will give you an idea how to get working on your capstone project. You can build web apps that are fully functional like makemytrip or oyo.</p>
                        </div>

                        <div id="fsdcs-module-10">
                            <h2>What are the learning takeaways from the capstone project?</h2>
                            <ol>
                                <li>Research skills- You have to research a valid real time problem and make copious notes on it before getting to the solution part.</li>
                                <li>Documentation- this is a very important part of the work for every developer. Keep precise notes and documentation of what you have created so that it is easy for you as well as others to understand and follow.</li>
                                <li>Coding- You learn to code and work with tools extensively for the project.</li>
                                <li>Experience – You get practical experience. When coding, not everything goes smoothly as in theory. You stumble into all sorts of errors while testing and writing codes. So this project will give you a taste of how the job is actually going to be.</li>
                                <li>Version control- You will save your work every time in the git repository and handle versions. This will also help you collaborate with teams and work towards a common goal.</li>
                                <li>Portfolio – You get a jump start on building your portfolio. You can use your capstone project and present it to the recruiter fto show how you work and what an asset you’ll be to their team.</li>
                            </ol>
                        </div>

                        <div id="fsdcs-module-11">
                            <h2>Here’s some capstone project ideas that students have worked with at Crampete!</h2>
                            <ul>
                                <li>Question and answer sites</li>
                                <li>School Management system</li>
                                <li>Project management tool</li>
                                <li>Social media site</li>
                                <li>Ticket booking site</li>
                            </ul>
                        </div>

                        <div id="fsdcs-module-12">
                            <h2>Mini Projects</h2>
                            <p>Minimum 5+ mini projects will be assigned to the learners. They may be each module(s) based. You need to work it out and finish it within the given time period. It will help you understand coding and working with the full stack tool for a specific purpose.</p>
                            <p>These projects could be something like creating a personal portfolio page, a landing page for a product, Shopping sites.</p>
                        </div>

                        <div id="fsdcs-module-13">
                            <h2>Lab Sessions</h2>
                            <p>These are hand-on practice sessions that are organized so that you can try coding what you’ve learnt during this course. There will be 16 sessions that will be marked labs. You will get this time to work on your coding expertise. Your teachers and mentors will help you with the learning process.</p>
                            <p>You get to try out text resizers using DOM manipulation, logic puzzles using onde.js, random quote generator, CRUD ops for node and mongo, google search page and many more.</p>
                        </div>

                        <div id="fsdcs-module-14">
                            <h2>Certification</h2>
                            <p>Once you have attended the classes, taen up your tests and completed your assignments and projects on time, you get a certificate of completion. This Crampete certification for full stack developer course is a value addition to your resume and is a proof of your skills.</p>
                        </div>

                    </div>
                </div>
                <div className="col-md-2"></div>
            </section>
        </Layout>
    );
}

export default fsdcs